import { BrowserRouter, Route } from "react-router-dom";
import logo from "./logo.svg";
import StartMeet from "./StartMeet";
import "./App.css";
import DuringVideoCall from "./DuringVideoCall";
import { ToastContainer, toast } from "react-toastify";

function App() {
    return (
        <BrowserRouter>
            <ToastContainer />
            <Route path="/" exact component={StartMeet} />
            <Route path="/meeting" exact component={DuringVideoCall} />
        </BrowserRouter>
    );
}

export default App;

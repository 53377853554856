import React, { Component } from "react";
import { VaniEvent } from "vani-meeting-client";
import VideoHandler from "./utilities/VideoHandler";
import VideoHolderModel from "./utilities/VideoHolderModel";
import VideoBox from "./VideoBox";
import { Track, TrackKind } from "vani-meeting-client/lib/model/Track";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sweetAlert from "sweetalert2";
import {
  MdMic,
  MdMicOff,
  MdVideocam,
  MdVideocamOff,
  MdVideoCamOn,
  MdCallEnd,
  MdSettings,
  MdContentCopy,
  MdOutlinePeopleAlt,
  MdOutlinePeople,
  MdCamera,
  MdClose,
} from "react-icons/md";
import { ReactComponent as Logo } from "./smartData-logo.svg";
import Setting from "./Setting";
import Utility from "./utilities/Utility";
import ParticipantBox from "./components/ParticipantBox";
export default class DuringVideoCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoHoldersModels: [],
      micOff: true,
      videoOff: true,
      showSetting: false,
      participantWindow: false,
      allParticipants: [],
    };
    this.onNewChatMessageReceived = this.onNewChatMessageReceived.bind(this);

    this.onTrack = this.onTrack.bind(this);
    this.onTrackEnded = this.onTrackEnded.bind(this);
    this.endCall = this.endCall.bind(this);

    this.checkAndAddUser = this.checkAndAddUser.bind(this);
    this.onUserLeft = this.onUserLeft.bind(this);
    this.onUserJoined = this.onUserJoined.bind(this);
    this.onAllParticipants = this.onAllParticipants.bind(this);
    this.switchVideo = this.switchVideo.bind(this);
    this.switchMic = this.switchMic.bind(this);
    this.copyLink = this.copyLink.bind(this);

    this.onVideoUnblocked = this.onVideoUnblocked.bind(this);
    this.onVideoBlocked = this.onVideoBlocked.bind(this);
    this.onAudioBlocked = this.onAudioBlocked.bind(this);
    this.onAudioUnblocked = this.onAudioUnblocked.bind(this);
  }
  componentDidMount() {
    if (
      !VideoHandler.getInstance().getMeetingRequest() ||
      VideoHandler.getInstance().getMeetingRequest() === null
    ) {
      window.location = window.location.origin;
      return;
    }
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      ?.on(VaniEvent.OnNewChatMessageReceived, this.onNewChatMessageReceived);
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .on(VaniEvent.OnUserLeft, this.onUserLeft);
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .on(VaniEvent.OnUserJoined, this.onUserJoined);

    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .on(VaniEvent.OnVideoUnblocked, this.onVideoUnblocked);
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .on(VaniEvent.OnAudioUnblocked, this.onAudioUnblocked);
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .on(VaniEvent.OnVideoBlocked, this.onVideoBlocked);
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .on(VaniEvent.OnAudioBlocked, this.onAudioBlocked);
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .on(VaniEvent.OnTrack, this.onTrack);
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .on(VaniEvent.OnTrackEnded, this.onTrackEnded);
    console.log(VideoHandler.getInstance().getMeetingRequest().userId);
    this.state.videoHoldersModels.push(
      new VideoHolderModel(
        VideoHandler.getInstance()
          .getMeetingHandler()
          .participantByUserId(
            VideoHandler.getInstance().getMeetingRequest().userId
          ),
        "Main",
        true
      )
    );
    this.setState({
      videoHoldersModels: [...this.state.videoHoldersModels],
    });

    this.onAllParticipants(
      VideoHandler.getInstance().getMeetingHandler().getAllParticipants()
    );

    VideoHandler.getInstance()
      .getMeetingHandler()
      .getAllTracks()
      .forEach((track) => {
        this.onTrack(track);
      });
  }

  onNewChatMessageReceived(messagePayload) {
    if (messagePayload.type === "PauseVideo") {
      VideoHandler.getInstance().getMeetingHandler().pauseCamera();
    } else if (messagePayload.type === "PauseAudio") {
      VideoHandler.getInstance().getMeetingHandler().muteUser();
    }
  }
  onAudioBlocked(participant) {
    if (
      participant.userId ===
      VideoHandler.getInstance().getMeetingRequest().userId
    ) {
      toast("Audio Blocked");
    }
  }
  onVideoBlocked(participant) {
    if (
      participant.userId ===
      VideoHandler.getInstance().getMeetingRequest().userId
    ) {
      toast("Camera Blocked");
    }
  }
  onAudioUnblocked(participant) {
    if (
      participant.userId ===
      VideoHandler.getInstance().getMeetingRequest().userId
    ) {
      toast("Audio Unblocked");
    }
  }
  onVideoUnblocked(participant) {
    if (
      participant.userId ===
      VideoHandler.getInstance().getMeetingRequest().userId
    ) {
      toast("Camera Unblocked");
    }
  }
  onTrack(track) {
    console.log("onTrack 12344");
    if (track.isLocalTrack && track.trackKind === TrackKind.Video) {
      this.setState({ videoOff: false });
    } else if (track.isLocalTrack && track.trackKind === TrackKind.Audio) {
      this.setState({ micOff: false });
    }
  }
  onTrackEnded(track) {
    if (track.isLocalTrack && track.trackKind === TrackKind.Video) {
      this.setState({ videoOff: true });
    } else if (track.isLocalTrack && track.trackKind === TrackKind.Audio) {
      this.setState({ micOff: true });
    }
  }
  onAllParticipants(allParticipants) {
    console.log("onAllParticipants", allParticipants);
    if (allParticipants.length > 0) {
      allParticipants.forEach((participant) => {
        this.checkAndAddUser(participant, "Main");
      });
    }
  }
  onUserLeft(participant) {
    console.log("onUserLeft");
    if(participant.isAdmin){
        this.endCall()
        return
    }
    const videos = this.state.videoHoldersModels.filter(
      (videoHoldersModel) =>
        videoHoldersModel.participant.userId !== participant.userId
    );
    this.setState({ videoHoldersModels: [...videos] });
    this.setState({
      allParticipants: VideoHandler.getInstance()
        .getMeetingHandler()
        .getAllParticipants(),
    });
  }
  onUserJoined(participant) {
    this.checkAndAddUser(participant, "Main");
    this.setState({
      allParticipants: VideoHandler.getInstance()
        .getMeetingHandler()
        .getAllParticipants(),
    });
  }
  checkAndAddUser(participant, viewHolderType) {
    const alreadyExitModel = this.state.videoHoldersModels.find(
      (viewHolder) =>
        viewHolder.participant.userId === participant.userId &&
        viewHolder.videoHolderModelType === viewHolderType
    );
    if (!alreadyExitModel) {
      this.state.videoHoldersModels.unshift(
        new VideoHolderModel(
          participant,
          viewHolderType,
          participant.userId ===
            VideoHandler.getInstance().getMeetingRequest().userId
        )
      );
      this.setState({
        videoHoldersModels: [...this.state.videoHoldersModels],
      });
    }
  }
  switchVideo() {
    if (this.state.videoOff) {
      if (
        VideoHandler.getInstance()
          .getMeetingHandler()
          .participantByUserId(
            VideoHandler.getInstance().getMeetingRequest().userId
          ).isVideoBlockedByAdmin
      ) {
        toast("Video Blocked");
      } else {
        VideoHandler.getInstance().getMeetingHandler().resumeCamera();
      }
    } else {
      VideoHandler.getInstance().getMeetingHandler().pauseCamera();
    }
  }
  switchMic() {
    if (this.state.micOff) {
      if (
        VideoHandler.getInstance()
          .getMeetingHandler()
          .participantByUserId(
            VideoHandler.getInstance().getMeetingRequest().userId
          ).isAudioBlockedByAdmin
      ) {
        toast("Audio Blocked");
      } else {
        VideoHandler.getInstance().getMeetingHandler().unmute();
      }
    } else {
      VideoHandler.getInstance().getMeetingHandler().muteUser();
    }
  }
  showEndCallAlert = () => {
    if(VideoHandler.getInstance().getMeetingRequest().isAdmin){
      sweetAlert
      .fire({
        title: "Do you want to end call?",
        // showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Exit",
        confirmButtonColor: "#d92029",
        // iconHtml: `<img src="https://www.clipartmax.com/png/middle/189-1898966_exit-clipart-exit-button-exit-button-icon-png.png" style="height:100px;width:100px" />`,
        cancelButtonText: "Cancel",
        showConfirmButton: true,
      })
      .then((btn) => {
        if (btn.isConfirmed) {
          this.endCall();
        }
        // console.log(btn);
      });
    }
    else{
      this.endCall()
    }
    
  };
  endCall() {
    VideoHandler.getInstance().cleanUp();
    window.location.href = "/";
  }
  renderClassOnVideos = () => {
    switch (this.state.videoHoldersModels.length) {
      case 1:
        return "one";
      case 2:
        return "two";
      case 3:
        return "three";
      default:
        return "default-grid";
    }
  };
  toggleSetting = () => {
    this.setState({ showSetting: !this.state.showSetting });
  };
  toggleParticipats = () => {
    this.setState({
      participantWindow: !this.state.participantWindow,
      allParticipants: VideoHandler.getInstance()
        .getMeetingHandler()
        .getAllParticipants(),
    });
  };

  dismissPartOutSide = (e) => {
    e.target === this.partRef && this.toggleParticipats();
  };
  copyLink() {
    const link =
      window.location.origin + "?vm=" + Utility.getRoomIdForMeeting();

    navigator.clipboard.writeText(link);
    toast("Link Copied!");
  }
  togglePopup() {
    this.setState({ participant: !this.state.participant });
  }
  render() {
    return (
      <div className={`video-container`}>
        <div className="logo">
          <Logo />
        </div>
        <div className={`video-wrap ${this.renderClassOnVideos()}`}>
          {this.state.videoHoldersModels.map((_videoHoldersModel) => {
            return (
              <div className="video_wrap">
                <VideoBox
                  key={_videoHoldersModel.participant.userId}
                  videoHolderModel={_videoHoldersModel}
                />
              </div>
            );
          })}
        </div>
        <div className="action-btn">
          <div className="btn" onClick={this.switchMic}>
            {this.state.micOff ? <MdMicOff size={25} /> : <MdMic size={25} />}
          </div>

          <div className="btn" onClick={this.switchVideo}>
            {this.state.videoOff ? (
              <MdVideocamOff size={25} />
            ) : (
              <MdVideocam size={25} />
            )}
          </div>
          <div className="btn danger" onClick={this.showEndCallAlert}>
            <MdCallEnd size={25} />
          </div>
          {/* <div className="btn" onClick={this.toggleSetting}>
            <MdSettings size={25} />
          </div> */}
          <div className="btn">
            <MdContentCopy size={25} onClick={this.copyLink} />
          </div>
          <div
            className="btn"
            onClick={() => {
              this.toggleParticipats();
            }}
          >
            <MdOutlinePeopleAlt size={25} /* onClick={this.togglePopup} */ />
          </div>
        </div>
        {this.state.showSetting ? (
          <Setting
            toggleSetting={this.toggleSetting}
            showSetting={this.state.showSetting}
          />
        ) : null}
        {this.state.participantWindow && (
          <div
            className="popup-box"
            ref={(ref) => (this.partRef = ref)}
            onClick={(e) => {
              this.dismissPartOutSide(e);
            }}
          >
            <div className="box">
              <div
                style={{
                  position: "absolute",
                  right: 15,
                  cursor: "pointer",
                  //   top: 50,
                }}
                onClick={this.toggleParticipats}
              >
                <MdClose size={26} />
              </div>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  fontWeight: 700,
                  textTransform: "uppercase",
                  marginBottom: 20,
                }}
              >
                Participants
              </p>
              {this.state.allParticipants.map((participant) => {
                return (
                  <ParticipantBox
                    participant={participant}
                    key={participant.userId}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const queryString = require("query-string");

class Utility {
  static getRoomIdFromLink() {
    const queryParmas = queryString.parse(window.location.search);
    console.log(queryParmas);
    if (queryParmas.vm && queryParmas.vm !== null) {
      return queryParmas.vm.toLowerCase();
    }
    return "";
  }
  static getRoomIdForMeeting(link) {
    if (!link) {
      const queryParmas = queryString.parse(window.location.search);
      console.log(queryParmas);
      if (queryParmas.vm && queryParmas.vm !== null) {
        return queryParmas.vm.toLowerCase();
      }
      return Math.random().toString(36).slice(2).toLowerCase();
    } else {
      console.log(link);
      const queryParmas = queryString.parseUrl(link);
      console.log(queryParmas);
      if (queryParmas.query.vm && queryParmas.query.vm !== null) {
        return queryParmas.query.vm.toLowerCase();
      }
      return Math.random().toString(36).slice(2).toLowerCase();
    }
  }
}

export default Utility;

import React, { Component } from "react";
import VideoHandler from "./utilities/VideoHandler";
import Utility from "./utilities/Utility";
import { ReactComponent as Logo } from "./smartData-logo.svg";
import { VaniEvent } from "vani-meeting-client";
import isURL from "is-url";
import { toast } from "react-toastify";
export default class StartMeet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      meeting: Utility.getRoomIdFromLink(),
      isChecked: false,
      loading: false,
    };
    this.updateMeetingId = this.updateMeetingId.bind(this);
    this.onUpdateName = this.onUpdateName.bind(this);
    this.onInitDone = this.onInitDone.bind(this);
    this.onPermissionError = this.onPermissionError.bind(this);
    this.onSocketConnected = this.onSocketConnected.bind(this);
    this.onPermissionApproved = this.onPermissionApproved.bind(this);
  }

  componentDidMount() {}
  componentWillUnmount() {
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .off(VaniEvent.OnInitDone, this.onInitDone);
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .off(VaniEvent.OnPermissionApproved, this.onPermissionApproved);
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .off(VaniEvent.OnPermissionError, this.onPermissionError);
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .off(VaniEvent.OnAllParticipants, this.onAllParticipants);
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .off(VaniEvent.OnSocketConnected, this.onSocketConnected);
  }
  startExistingMeet = (e) => {
    e.preventDefault();
    if (!this.state.name) {
      toast("Name is required");
      return;
    }
    console.log("handleSubmit");
    const isLink = isURL(this.state.meeting);
    console.log(isLink, this.state.meeting);
    const roomId = isLink
      ? Utility.getRoomIdForMeeting(this.state.meeting)
      : this.state.meeting;
    VideoHandler.getInstance().isNewMeeting = false;
    VideoHandler.getInstance().setup(roomId, { name: this.state.name }, false);
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .on(VaniEvent.OnInitDone, this.onInitDone);
    VideoHandler.getInstance().getMeetingHandler().init();
    //Show Loader
    this.setState({ loading: true });
  };
  onInitDone() {
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .on(VaniEvent.OnPermissionApproved, this.onPermissionApproved);
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .on(VaniEvent.OnPermissionError, this.onPermissionError);
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .on(VaniEvent.OnAllParticipants, this.onAllParticipants);
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .on(VaniEvent.OnSocketConnected, this.onSocketConnected);
    VideoHandler.getInstance().getMeetingHandler().startLocalStream(true, true);
  }

  onPermissionError() {
    alert("Please Allow Permission");
  }
  onPermissionApproved() {
    VideoHandler.getInstance().getMeetingHandler().checkSocket();
  }
  onAllParticipants = (participants) => {
    console.log(participants);
    if (
      VideoHandler.getInstance().isNewMeeting === false &&
      participants.length < 2
    ) {
      VideoHandler.getInstance().cleanUp();
      this.setState({ loading: false });
      toast("No Meeting Found", {
        type: "error",
      });
      //Hide loader
    } else {
      this.props.history.push(
        "/meeting?vm=" + VideoHandler.getInstance().getMeetingRequest().roomId
      );
    }
  };

  onSocketConnected() {
    if (VideoHandler.getInstance().isNewMeeting === false) {
      VideoHandler.getInstance()
        .getMeetingHandler()
        .getUpdatedParticipantsListFromServer();
    }
    VideoHandler.getInstance().getMeetingHandler().startMeeting();
    if (VideoHandler.getInstance().isNewMeeting) {
      //Hide loader
      this.setState({ loading: false });
      this.props.history.push(
        "/meeting?vm=" + VideoHandler.getInstance().getMeetingRequest().roomId
      );
    }
  }
  startnewMeet = (e) => {
    e.preventDefault();
    console.log("handleSubmit");
    if (!this.state.name) {
      toast("Name is required");
      return;
    }
    const roomId = Utility.getRoomIdForMeeting();
    VideoHandler.getInstance().setup(roomId, { name: this.state.name }, true);
    VideoHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .on(VaniEvent.OnInitDone, this.onInitDone);
    VideoHandler.getInstance().getMeetingHandler().init();
    //Show Loader
    this.setState({ loading: true });

    // this.props.history.push("/meeting?vm=" + roomId);
  };
  onUpdateName(event) {
    this.setState({ name: event.target.value });
  }
  updateMeetingId(event) {
    this.setState({ meeting: event.target.value });
  }
  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };
  render() {
    return (
      <div className="start-container">
        {this.state.loading && (
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              backgroundColor: "rgba(0,0,0,0.3)",
            }}
          >
            <img
              src="loading_anim.gif"
              alt="loader"
              style={{
                width: 50,
                height: 50,
              }}
            />
          </div>
        )}
        <div className="navbar">
          <Logo />
        </div>
        <div className="startmeet">
          <div className="form">
            <div className="startmeet_p">
              <p
                style={{
                  fontSize: 26,
                  fontWeight: 500,
                }}
              >
                Premium video meetings. Powered by smartData.
              </p>
              <p
                style={{
                  color: "#5f6368",
                  fontSize: 15,
                }}
              >
                Connect, collaborate and celebrate from anywhere with smartData
              </p>
            </div>
            <input
              type={"text"}
              className="field"
              placeholder="Your Name"
              value={this.state.name}
              onChange={(evt) => {
                this.onUpdateName(evt);
              }}
              required
            />
            <div className="bottom">
              <button onClick={this.startnewMeet}>New Meeting</button>
              <input
                className="field"
                type={"text"}
                style={{
                  margin: 0,
                }}
                placeholder="Enter link or code"
                onChange={(evt) => {
                  this.updateMeetingId(evt);
                }}
                value={this.state.meeting}
                required
              />
              <button
                onClick={this.startExistingMeet}
                disabled={!this.state.meeting}
              >
                join
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
